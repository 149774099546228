import React from "react";
import Img from "gatsby-image";
import styles from "./team.module.css"
import {Row, Col} from 'react-bootstrap';


const Team = ({photo, name, position, description}) => {

  return (
      <Row className={styles.resourcesContent}>
        <Col md={5} sm={5} xs={12} className={styles.imageContent}>
          <Img fluid={photo} />
        </Col>
        <Col md={7} sm={7} xs={12} className={styles.detailsContent} >
          <div className={styles.name} >{name}, {position}</div>
          <div className={styles.description}>{description}</div>
        </Col>
        <div style={{clear: "both"}}></div>
      </Row>
  )
};

export default Team


