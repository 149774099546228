import React from "react"
import Layout from "../components/layout";
import globalStyle from "../styles/global.module.css";
import momTeamStyles from "./mom-team.module.css";
import Team from "../components/team";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";

const MomTeamPage =  ({data}) => {
  const resourceImages = {};
  data.allFile.edges.map(item => {
    resourceImages[item.node.name] = item.node;
  });

  return (
    <Layout className="site-content">
      <Container>
        <div className={momTeamStyles.resourcesContainer}>
          <div>
            <h1 className={globalStyle.entryTitle}>MOM Team</h1>
          </div>
          <div className={momTeamStyles.momTeam}>
            {data.site.siteMetadata.momTeam.map(member => (
              <Team key={member.name}
                photo={resourceImages[member.photo]['childImageSharp']['fluid']}
                name={member.name}
                position={member.position}
                description={member.description}>
              </Team>
            ))}
          </div>
        </div>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        momTeam {
          name
          position
          description
          photo
        }    
      }
    }
 
    allFile(filter: {relativePath: {regex: "/team\/.*/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    massageLogo: file(relativePath: { eq: "massage/bambino-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yogaLogo: file(relativePath: { eq: "yoga/do-yoga-with-me-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
  }
`;

export default MomTeamPage
